<template>
  <div class="container-fluid">
    <div class="card">
        <div id="report" class="card-body">
            <div class="container-fluid">
              <div style="width: 80px;">
                <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
              </div>
              <div class="headerreport">
                <div class="row">
                  {{ companydata.cmp_nmbre }}
                </div>
                <div class="row">
                  {{ fecha }}
                </div>
              </div>
            </div>
            <br>
            <div class="card-title">
              <h1 class="titlereport">Reporte de solicitudes respondidas</h1>
              <h5 class="subtitle" v-if="$route.params.query.pqrs">Sólo Tipos de Pqrs</h5>
            </div>
            <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                <table id="reporteTiemposRespuesta" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Clase Doc.</th>
                            <th>Año</th>
                            <th>Consecutivo</th>
                            <th>Tipo documento</th>
                            <th>Tipo solicitud</th>
                            <th>Fecha Radicación</th>
                            <th class="entidad">Entidad</th>
                            <th class="asunto">Asunto</th>
                            <th>Ciudad</th>
                            <th class="dependencia">Dependencia</th>
                            <th class="serie">Serie</th>
                            <th>Índices</th>
                            <th>Tramitado por</th>
                            <th>Último seguimiento (De usuario)</th>
                            <th>Pendiente Respuesta</th>
                            <th>Tiempo estándar</th>
                            <th>Tiempo asignado</th>
                            <th>Tiempo utilizado</th>
                            <th>Diferencia</th>
                            <th>Respuesta</th>
                            <th>Fecha respuesta</th>
                        </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in items">
                        <tr class="rowstyle">
                          <td>{{ index + 1 }}</td>
                          <td>{{ item.tcr_cdgo }}</td>
                          <td>{{ item.dcm_ano }}</td>
                          <td>{{ item.dcm_cdgo }}</td>
                          <td>{{ item.tdo_nmbre }}</td>
                          <td>{{ item.tpq_nmbre }}</td>
                          <td>{{ item.dcm_fcharad | formatDate }}</td>
                          <td>{{ item.dcm_entidad }}</td>
                          <td>{{ item.dcm_asunto }}</td>
                          <td>{{ item.dcm_ciudad }}</td>
                          <td>{{ item.dep_nmbre }}</td>
                          <td>{{ item.ser_nmbre }}</td>
                          <td>
                            <table class="table table-sm">
                              <tr v-for="i in item.indices">
                                <td>{{ i.ind_nmbre }}</td>
                                <td>{{ i.ixd_valor }}</td>
                              </tr>
                            </table>
                          </td>
                          <td>{{ item.dep_tramite_nmbre }}</td>
                          <td>{{ item.usu_nmbre }}</td>
                          <td>{{ item.dcm_rspsta | formatBoolean }}</td>
                          <td>{{ item.t_estandar }}</td>
                          <td>{{ item.dcm_tres }}</td>
                          <td>{{ item.t_utilizado }}</td>
                          <td>{{ item.t_diferencia }}</td>
                          <td>{{ item.respuesta }}</td>
                          <td>{{ item.fecha_respuesta | formatDate }}</td>
                        </tr>
                        <tr v-for="(item2, index2) in item.expediente_docs" class="rowstyle">
                          <td align="right">*</td>
                          <td>{{ item2.tcr_cdgo }}</td>
                          <td>{{ item2.dcm_ano }}</td>
                          <td>{{ item2.dcm_cdgo }}</td>
                          <td>{{ item2.tpq_nmbre }}</td>
                          <td>{{ item2.dcm_fcharad | formatDate }}</td>
                          <td>{{ item2.dcm_entidad }}</td>
                          <td>{{ item2.dcm_asunto }}</td>
                          <td>{{ item2.dep_nmbre }}</td>
                          <td>{{ item2.ser_nmbre }}</td>
                          <td>
                            <table class="table table-sm">
                              <tr v-for="i in item2.indices">
                                <td>{{ i.ind_nmbre }}</td>
                                <td>{{ i.ixd_valor }}</td>
                              </tr>
                            </table>
                          </td>
                          <td>{{ item2.dep_tramite_nmbre }}</td>
                          <td></td>
                          <td>{{ item2.dcm_rspsta | formatBoolean }}</td>
                          <td>{{ item2.t_estandar }}</td>
                          <td>{{ item2.dcm_tres }}</td>
                          <td>{{ item2.t_utilizado }}</td>
                          <td>{{ item2.t_diferencia }}</td>
                          <td>{{ item2.respuesta }}</td>
                          <td>{{ item2.fecha_respuesta | formatDate }}</td>
                        </tr>
                      </template>
                    </tbody>
                    <tr style="font-weight:bold">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>PROMEDIO</td>
                        <td>{{ promedio_test.toFixed(1) }}</td>
                        <td>{{ promedio_tres.toFixed(1) }}</td>
                        <td>{{ promedio_tutil.toFixed(1) }}</td>
                        <td>{{ promedio_tdif.toFixed(1) }}</td>
                        <td></td>
                        <td></td>
                    </tr>
                </table>
            </div>
            <br>
            <div v-if="showProgress" style="text-align:center">
              <v-progress-circular
                :size="50"
                :width="5"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
        </div>
    </div>
    <br>
    <div class="row justify-content-around" align="center">
      <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
      <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
    </div>
  </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate, formatBoolean } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';
import { tiempoUtilizado } from '../../utils';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      tpodocs: [],
      tpopqrs: [],
      dependencias: [],
      series: [],
      indices: [],
      debug: null,
      fecha: null,
      tcr_nmbre: '',
      showProgress: false,
      promedio_test: 0,
      promedio_tres: 0,
      promedio_tutil: 0,
      promedio_tdif: 0,
      dnl: [],
      suma_test: 0,
      suma_tres: 0,
      suma_tutil: 0,
      suma_tdif: 0
    }
  },
  filters: {
    formatDate, formatBoolean
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchTpocor(err => {
      if (err){
        alert('Error al consultar las clases de documentos.');
      } else {
        this.fetchTpodoc(err => {
          if (err){
            alert('Error al consultar los tipos de documento.');
          } else {
            this.fetchTpopqrs(err => {
              if (err){
                alert('Error al consultar los tipos de pqrs.');
              } else {
                this.fetchDependencia(err => {
                  if (err){
                    alert('Error al consultar las dependencias.');
                  } else {
                    this.fetchSerie(err => {
                      if (err){
                        alert('Error al consultar las series.');
                      } else {
                        this.fetchIndice(err => {
                          if (err){
                            alert('Error al consultar los índices.');
                          } else {
                            this.fetchDianol(err => {
                              if (err){
                                alert('Error al consultar los días no laborales.');
                              } else {
                                this.debug = this.$route.params;
                                this.tcr_nmbre = this.$route.params.tcr_nmbre;
                                this.searchItems(this.$route.params.query);
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor(callback){
      let uri = '/tpocors/correspondencia/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchTpodoc(callback){
      let uri = '/tpodocs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpodocs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchTpopqrs(callback){
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpopqrs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchSerie(callback){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchIndice(callback){
      let uri = '/indices/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.indices = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDianol(callback){
      var p = {};
      p.cmp_id = this.$store.state.company;
      p.inicio = this.$route.params.query.dcm_fcharadini;
      p.fin = this.$route.params.query.dcm_fcharadfin;

      let uri = '/dianols/rango';
      this.axios.post(uri, p)
      .then((response) => {
        this.dnl = response.data;
        return callback(false);
      })
      .catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/tiempos_respuesta';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];

          for (var i = 0; i < list.length; i++){
            this.procesarItem(list[i], false);
            
            if (list[i].expediente_docs != undefined){
              if (list[i].expediente_docs.length > 0){
                for (var j = 0; j < list[i].expediente_docs.length; j++){
                  this.procesarItem(list[i].expediente_docs[j], true);
                }
              }
            }                        
          }

          if (this.items.length > 0){
            this.promedio_test = this.suma_test/this.items.length;
            this.promedio_tres = this.suma_tres/this.items.length;
            this.promedio_tutil = this.suma_tutil/this.items.length;
            this.promedio_tdif = this.suma_tdif/this.items.length;
          }

          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    procesarItem(item, relacionado){
      for (var j = 0; j < this.tpocors.length; j++){
        if (item.tcr_id == this.tpocors[j]._id){
          item.tcr_cdgo = this.tpocors[j].tcr_cdgo;
          j = this.tpocors.length;
        }
      }

      for (var j = 0; j < this.tpodocs.length; j++){
        if (item.tdo_id == this.tpodocs[j]._id){
          item.tdo_nmbre = this.tpodocs[j].tdo_nmbre;
          j = this.tpodocs.length;
        }
      }

      for (var j = 0; j < this.dependencias.length; j++){
        if (item.dep_id == this.dependencias[j]._id){
          item.dep_nmbre = this.dependencias[j].dep_nmbre;
          j = this.dependencias.length;
        }
      }

      if (item.indices.length > 0){
        for (var j = 0; j < item.indices.length; j++){
          for (var k = 0; k < this.indices.length; k++){
            if (item.indices[j].ind_id == this.indices[k]._id){
              item.indices[j].ind_nmbre = this.indices[k].ind_nmbre;
              k = this.indices.length;
            }
          }
        }
      }

      var t_estandar = 0;
      
      for (var j = 0; j < this.series.length; j++){
        if (item.ser_id == this.series[j]._id){
          item.ser_nmbre = this.series[j].ser_nmbre;
          t_estandar = this.series[j].ser_tres;
          j = this.series.length;
        }
      }

      for (var j = 0; j < this.tpopqrs.length; j++){
        if (item.tpq_id == this.tpopqrs[j]._id){
          item.tpq_nmbre = this.tpopqrs[j].tpq_nmbre;
          if (this.tpopqrs[j].tpq_tres){
            t_estandar = this.tpopqrs[j].tpq_tres;
          }                
          j = this.tpopqrs.length;
        }
      }
      if (!item.tpq_nmbre){
        item.tpq_nmbre = 'Otra solicitud';            
      }

      if (item.usuario.length > 0){
        item.usu_nmbre = item.usuario[0].usu_nmbre + ' ' + item.usuario[0].usu_aplldo;
      }

      if (!relacionado){
        item.t_estandar = t_estandar;
        if (!item.dcm_tres){
          item.dcm_tres = t_estandar;
        }

        if (item.dcmnt_docs.length > 0){
          for (var j = 0; j < this.dependencias.length; j++){
            if (item.dcmnt_docs[0].dep_tramite_id == this.dependencias[j]._id){
              item.dep_tramite_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }

        this.suma_test += item.t_estandar;
        this.suma_tres += item.dcm_tres;

        if (item.dcmnt_docs.length > 0){
          item.t_utilizado = tiempoUtilizado(item.dcm_fcharad, item.dcmnt_docs[0].dcm_fcharad, this.dnl);
          item.t_diferencia = item.dcm_tres - item.t_utilizado;
          this.suma_tutil += item.t_utilizado;
          this.suma_tdif += item.t_diferencia;
          
          for (var j = 0; j < this.tpocors.length; j++){
            if (item.dcmnt_docs[0].tcr_id == this.tpocors[j]._id){
              item.respuesta = this.tpocors[j].tcr_cdgo + '-' + item.dcmnt_docs[0].dcm_ano + '-' + item.dcmnt_docs[0].dcm_cdgo;
              j = this.tpocors.length;
            }
          }
          item.fecha_respuesta = item.dcmnt_docs[0].dcm_fcharad;
        }

        this.items.push(item);
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteSolicitudesRespondidas';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        report.push(this.procesarItemExport(this.items[i], i));

        if (this.items[i].expediente_docs != undefined){
          if (this.items[i].expediente_docs.length > 0){
            for (var j = 0; j < this.items[i].expediente_docs.length; j++){
              report.push(this.procesarItemExport(this.items[i].expediente_docs[j], -1));
            }
          }
        }            
      }

      let item = {};
      item.dcm_rspsta = 'PROMEDIO';
      item.t_estandar = this.promedio_test;
      item.dcm_tres = this.promedio_tres;
      item.t_utilizado = this.promedio_tutil;
      report.push(item);

      var pqrs = this.$route.params.query.pqrs ? "Sólo Tipos de Pqrs" : "";

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de solicitudes respondidas"],
        [pqrs],
        [],
        ["#", "Clase documento", "Año", "Consecutivo", "Tipo documento", "Tipo solicitud", "Fecha Radicación", "Entidad", "Asunto", "Ciudad", "Dependencia", "Serie", "Índices", "Tramitado por", "Último seguimiento (De usuario)", "Pendiente respuesta", "Tiempo estándar", "Tiempo asignado", "Tiempo utilizado", "Respuesta", "Fecha Respuesta"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    },
    procesarItemExport(obj, i){
      let item = {};
      if (i > -1){
        item.index = i + 1;
      } else {
        item.index = '*';
      }
      item.tcr_cdgo = obj.tcr_cdgo;
      item.dcm_ano = obj.dcm_ano;
      item.dcm_cdgo = obj.dcm_cdgo;
      item.tdo_nmbre = obj.tdo_nmbre;
      item.tpq_nmbre = obj.tpq_nmbre;
      item.dcm_fcharad = moment(obj.dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
      item.dcm_entidad = obj.dcm_entidad;
      item.dcm_asunto = obj.dcm_asunto;
      item.dcm_ciudad = obj.dcm_ciudad;
      item.dep_nmbre = obj.dep_nmbre;
      item.ser_nmbre = obj.ser_nmbre;
      item.ser_nmbre = obj.ser_nmbre;
      item.indices = '';
      if (obj.indices.length > 0){
        for (var j = 0; j < obj.indices.length; j++){
          item.indices += '{ ' + obj.indices[j].ind_nmbre + ': ' + obj.indices[j].ixd_valor + ' }';
        }
      }
      item.dep_tramite_nmbre = obj.dep_tramite_nmbre;
      item.usu_nmbre = obj.usu_nmbre;
      item.dcm_rspsta = obj.dcm_rspsta ? 'Si' : 'No';
      item.t_estandar = obj.t_estandar;
      item.dcm_tres = obj.dcm_tres;
      item.t_utilizado = obj.t_utilizado;
      item.respuesta = obj.respuesta;
      if (obj.fecha_respuesta){
        item.fecha_respuesta = moment(obj.fecha_respuesta).format('DD/MMM/YYYY hh:mm A');
      }

      return item;      
    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

</style>
